import React, { useEffect, useState } from 'react';
import { Segment, Button } from 'semantic-ui-react';
import { StaticQuery, graphql } from 'gatsby';
import HeaderComponent from '../components/common/header';
import Footer from '../components/common/footer';
import ReactPlayer from 'react-player';
import AOS from 'aos';
import 'aos/dist/aos.css';  // 引入 AOS 的 CSS 文件
import { Helmet } from 'react-helmet';  // Import Helmet

const About = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200,  // 动画持续时间 (毫秒)
      once: true,      // 页面加载后动画只执行一次
    });

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query AboutPageBannerQuery {
          contentfulOtherPages {
            aboutPageTitle
            aboutPageSubtitle {
              aboutPageSubtitle
            }
            aboutPageDownloadFile {
              title
              file {
                url
              }
            }
            aboutPageVideo {
              file {
                url
              }
            }
            aboutPageParagraph {
              json
            }
          }
        }
      `}
      render={data => {
        const aboutPage = data.contentfulOtherPages;

        return (
          <div style={{ background: '#F2F2F2' }}>
            <Helmet>
              <title>About - DP Tile and Stone</title>
              <meta name="description" content={aboutPage.aboutPageSubtitle.aboutPageSubtitle} />
            </Helmet>
            <Segment
              inverted
              textAlign='center'
              vertical
              style={{ minHeight: '860px', position: 'relative' }}
              className="about-banner"
              data-aos="fade-in"
            >
              <HeaderComponent page="about" />
              <div className={`react-player-wrapper ${isScrolled ? 'scrolled' : ''}`}>
                <ReactPlayer
                  className="react-player"
                  url={aboutPage.aboutPageVideo.file.url}
                  width="100%"
                  height="100%"
                  controls
                  playing={true}
                  muted={true}
                />
              </div>
            </Segment>
            <div className="aboutUsSection" data-aos="fade-up">
              <div className="aboutUsContainer">
                <div className="aboutUsLeft" data-aos="fade-right">
                  <p className="aboutUsTitle">About DP Tile and Stone</p>
                  <p className="aboutUsMainStatement">
                    <em>Delivering one-stop tile shop experience with premium products and exceptional service.</em>
                  </p>
                </div>
                <div className="aboutUsRight" data-aos="fade-left">
                  <p className="aboutUsDetail">
                    Founded in 1972, Dongpeng is a leader in the tile industry, offering a select range of porcelain tiles known for quality and durability to our distributors and their customers.
                  </p>
                  <p className="aboutUsDetail">
                    At DP Tile and Stone, we back each porcelain product with comprehensive support, ensuring every customer receives tailored guidance from selection to installation.
                    Committed to excellence, we provide a seamless purchase experience, aiming to deliver quality and service that respect your time and investment.
                  </p>
                </div>
              </div>
              <div className="aboutUsImageContainer">
                <img src="https://i.postimg.cc/sx2Q7mrn/1.png" alt="Elegant Porcelain Tiles" className="responsiveImage" data-aos="zoom-in" />
                <img src="https://i.postimg.cc/wTZyRDbV/2.png" alt="Elegant Porcelain Tiles" className="responsiveImage" data-aos="zoom-in" />
                <img src="https://i.postimg.cc/3rLD7sXz/3.png" alt="Elegant Porcelain Tiles" className="responsiveImage" data-aos="zoom-in" />
                <img src="https://i.postimg.cc/htmJLhkq/4.png" alt="Elegant Porcelain Tiles" className="responsiveImage" data-aos="zoom-in" />
              </div>
            </div>
            <div className="about-sendCV" data-aos="fade-up">
              <p className="about-sendCV-title">Work with us</p>
              <p className="about-sendCV-content">Want to join our team? Send us your CV at info@dptile.ca.</p>
              <a
                className="mailto"
                href="mailto:hr@dptile.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>Send CV</Button>
              </a>
            </div>
            <Footer />
          </div>
        )
      }}
    />
  );
}

export default About;
